import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    this.fonts
  }

  get fonts() {
    var WebFont = require('webfontloader')
    WebFont.load({
      google: {
        families: ['Lato:300,400,700']
      }
    });
  }
}
